.header{
  color: white;
  padding-top: 1em;
  line-height: 2em;
}

.itemTitle{
  font-size: 2.7em;
  text-decoration: underline;
  color: #ffeb01;
  font-weight: 800;
  line-height: 1.1em;
}

.waitForMore{
  color: #ffeb01;
  font-weight: 800;
  line-height: 1.1em;
  text-align: center;
}

.itemPrice{
  font-size: 1.5em;
  font-weight: 400;
}

.itemSize{
  font-size: 1.3em;
}

.buyButton{
  /*background-color: #ffeb01 !important;*/
  color: rgb(30, 30, 30) !important;
  font-family: inherit !important;
  font-weight: 800 !important;
}

.round{
  border-radius: 50%;
  background-color: white;
}

.pictureContainer{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.quantityCounter{
  border: 2px solid white;
  font-weight: 800;
  padding: 0.6em;
  border-radius: 15%;
  font-size: 1.4em;
  color: #e24a97;
  background-color: white;
  min-width: 1em;
  text-align: center;
}

input,
label{
  font-family: 'Poppins', sans-serif !important;
  font-size: 0.9em !important;
}

span{
  font-family: 'Poppins', sans-serif !important;
}

.MuiCheckbox-root .MuiSvgIcon-root{
  color: #ffeb01 !important;
}

.MuiRadio-root.Mui-checked{
  color: #ffeb01 !important;
}

.recapTable .l{
  color: rgb(30, 30, 30);
}

.footer{
  background-color: white;
  color: rgb(30, 30, 30);
  text-align: center;
  font-size: 0.9em;
  padding: 0.5em 0;
}


.footer a,
.footer a:link,
.footer a:hover,
.footer a:active{
  color: inherit;
  text-decoration: underline;
}

.termsSpan a,
.termsSpan a:link,
.termsSpan a:hover,
.termsSpan a:active{
  color: inherit;
  text-decoration: underline;
  font-weight: bold;
}

.footer span{
  font-size: 0.6em;
}

.thanks{
  font-size: 5em;
  text-decoration: underline;
  color: #ffeb01;
  font-weight: 800;
  text-align: center;
}

.MuiSnackbar-root{
  bottom: 3.5em !important;
}

.spinnerContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  padding-top: 2em;
}