html, body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e24a97;
  color: white;
  min-height: 100vh;
  scroll-behavior: smooth;
}

#root{
  min-height: 100vh;
}